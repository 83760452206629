/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

body{
  background: linear-gradient(180deg, #EBF7FF 0%, #FFF 50%) no-repeat;
}

.btn, .card{
  border-radius: .25em ;
}

.btn, .cpca .btn:disabled, .cpca .btn.disabled {
  --bs-btn-disabled-opacity: 0.35 !important;
}


:root {
  --lw-chart-purple: rgba(92 97 136 / 85%);
  --lw-chart-blue: rgb(12 164 197 / 85%);
  --lw-chart-green: rgb(111 207 151 / 85%);
  --lw-chart-yellow: rgb(249 188 96 / 85%);
  --lw-chart-red: rgba(249 96 123 / 85%);
  --lw-chart-not-reviewed: red;
  --lw-blue-01: #5EADE0;
  --lw-blue-02: #265188;
  --lw-blue-03: #dff1f9;
  --lw-blue-04: #f5f9fa;
  --lw-green-01: #57ae8e;
  --lw-green-02: #B1E299;
  --lw-red-01:#8c3543;
  --lw-red-02:#F9607B;
  --lw-paragraph-font-family: 'Roboto', sans-serif;
  --lw-completion: lightskyblue;
  --lw-score: #B1E299;
  --lw-white: #fff;
  --lw-not-white: #FEFEFE;
  --lw-grey-toolbar: #f9f9f9;
  --lw-grey-pinline: #ddd;
  --lw-grey-mid: #bdbdbd;
  --lw-grey-dark: #797979;
  --lw-grey-darkest: #555;
  --lw-not-black: #333;
  --lw-black: #000;
  --lw-gradient-light: linear-gradient(180deg, var(--lw-grey-toolbar) 0%, var(--lw-not-white) 11.98%, var(--lw-not-white) 86.98%, var(--lw-grey-toolbar) 100%);
  --lw-gradient-dark: linear-gradient(180deg, var(--lw-grey-darkest) 0%, var(--lw-not-black) 11.98%, var(--lw-not-black) 86.98%, var(--lw-grey-darkest) 100%);

  /* abpm */
  --abpm-magenta: #a81874;
  --abpm-blue: #033850;
  --abpm-white: #ffffff;

  /* silkoak */

  --silkoak-header-bg: radial-gradient(91.89% 141.42% at 0% 0%, #E100FF 0%, #80F 100%);

  /* BS Overrides */
  --bs-font-sans-serif: 'Roboto', sans-serif;
  --bs-primary: var(--lw-blue-02);
  --bs-secondary: var(--lw-green-01);
  --bs-btn-border-radius:0;
  --bs-success-rgb: 111 207 151;
  --bs-danger-rgb: 249 96 123;
  --bs-link-color-rgb: rgb(12 164 197);
  --bs-primary-rgb: rgb(12 164 197);
  --bs-border-radius: 0;
}
.text-lw-blue-01{
  color: var(--lw-blue-01);
}
.text-lw-blue-02{
  color: var(--lw-blue-02);
}

.confident-wrong {
  color: #F9607B;
}

.unsure-wrong {
  color: #E1BE89;
}

.confident-right {
  color: #6FCF97;
}

.unsure-right {
  color: #5C6188;
}

.fail{
  color: var(--lw-red-02);
}

.success{
  color: var(--lw-green-02);
}

.btn-primary {
  --bs-btn-bg: var(--lw-blue-02);
  --bs-btn-border-color: var(--lw-blue-02);
  --bs-btn-hover-bg: var(--lw-blue-01);
  --bs-btn-hover-border-color: var(--lw-blue-01);
  --bs-btn-active-bg: var(--lw-blue-02);
  --bs-btn-active-border-color: var(--lw-blue-02);

  transition: all .25s ease-in-out;
}

.btn-outline-primary {
  --bs-btn-color: var(--lw-blue-02);
  --bs-btn-border-color: var(--lw-blue-02);
  --bs-btn-hover-border-color: var(--lw-blue-01);
  --bs-btn-hover-bg: var(--lw-blue-01);
  --bs-btn-active-bg: var(--lw-blue-02);
  --bs-btn-active-border-color: var(--lw-blue-02);
}

.btn-secondary{
  background-color: var(--lw-green-01);
  border-color: var(--lw-green-01);
}

.fill-primary{
  fill:#315084;
}

.fill-secondary{
  fill:#6eaee7;
}

.fill-tertiary{
  fill:var(--lw-green-01);
}

table td {
  vertical-align: middle;
}


.fa-spin svg{
  animation: spinner 0.75s infinite;
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {-webkit-transform: rotate(360deg);
    transform: rotate(360deg);}
}

/* temp override until we can override bs components */
.subscription .progress-bar{
  background:var(--lw-blue-01) !important;
}




#submenu-nav a {
  color: var(--lw-grey-dark);
  border-bottom:2px solid transparent;
  text-decoration:none;
  padding: 0 0 12px;
  transition:all .4s;
  font-size:13px;
}

#submenu-nav a:hover {
  color:var(--lw-blue-01);
  border-color:var(--lw-blue-01);
  transition:all .2s;
}

#submenu-nav a.active{
  color:var(--lw-blue-02);
  border-color:var(--lw-blue-02);
  font-weight:bold;
}

#context-header,
.page-context {
  height: 100px;
  width: 100%;
  padding: 20px 32px;
  display: flex;
  justify-content: center;
  background: var(--lw-gradient-light);
  border-bottom: solid 1px var(--lw-grey-pinline);
  margin: 0;
  position: sticky;
  top: 0;
  z-index:1021;
}

.page-context {
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
}

.page-context h1 {
  letter-spacing: -1.5px;
}

.page-context.small{
  z-index:5;
  height: 50px;
  animation: slide-down .25s 1;
}

@keyframes slide-down{
  0%  {
    opacity:0;
    margin-top:-60px;
    height:0;
  }

  100%{
    margin-top:0;
    height:initial;
    opacity:1;
  }

}

.font-xs {
  font-size: 65% !important;
}

.legend > div{
  margin-top:5px;
}

.legend .text-wrap{
  font-size:13px;
  color: #777;
  display:flex;
  flex-direction: column;
  justify-content: center;
}

.legend .label{
  font-weight:bold;
}

.legend .label-description{
  font-size: 12px;
  line-height: 1.1;
}

.clickable {
  cursor: pointer;
}

.section h5{
  text-transform:uppercase;
  font-size:14px;
  color: var(--lw-grey-dark);
}


.alert.alert-success {
  border-left: solid 8px var(--lw-green-01);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.alert.alert-danger {
    border-left: solid 8px var(--lw-red-02);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

.alert.alert-info {
  border-left: solid 8px var(--lw-blue-01);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.alert-success,
.alert-danger,
.alert-warning,
.alert-info{
  color:#333;
  border-width:0;
  border-left-width: 4px;
}

.alert-success{
  border-color:var(--lw-green-01);
  background:rgb(177 226 153 / 14%);
}

.alert-danger{
  border-color:var(--lw-chart-red);
  background:rgba(249 96 123 / 5%);
}

.alert.alert-warning{
  border-left:4px solid burlywood;
  background: #ffe69c;
}

.alert-info{
  border-color:var(--lw-blue-01);
  background:var(--lw-blue-04);
}

.alert p:last-of-type {
  margin-bottom: 0;
}

.donut-container{
  max-width: 400px;
}

.data-title{
  color: var(--lw-grey-dark);
  font-size: 18px;
  font-weight: 600;
}

.data-value{
  font-size:32px;
  color:var(--lw-grey-darkest);
  letter-spacing:-1.5px;
  font-weight:300;
}

.attempt-history fa-icon.success svg{
  background: white;
  border-radius: 50%;
  border: solid 2px white;
}

.nda-footer{
  box-shadow:0 0 10px rgba(0 0 0 / 15%);
}

@media screen and (max-width: 992px) and (min-width: 768px){
  .data-description{
    font-size:85%;
  }

}

.connectors {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  transition: all 1s;
  overflow: hidden;
  height: 75px;
  z-index:20;

  svg {
    height: 23px;
  }

  svg:first-child {
    width: 250px;
    animation-name: slide-from-right;
    animation-duration: .5s;
  }

  svg:last-child {
    width: 300px;
    animation-name: slide-from-right;
    animation-duration: .75s;
  }
}

.connectors.top{
  top: 4em;
  right:0;
}

.connectors.bottom {
  bottom: 4em;
  left: 0;
  transform: rotate(180deg);
}

@keyframes slide-from-right {
  0% {
    margin-right: -400px
  }

  25% {
    margin-right: -300px
  }

  100% {
    margin-right: 0
  }
}

hr{
  margin:2em 0;
}


/* Paradise */
.zed-20{
  z-index:20;
  position:relative;
}

#sky{
  position:absolute;
  top:0;
  left:0;
}

#sky #circle-01{
  position:fixed;
  left:0;
  top:25vh;
}
#sky #circle-02{
  position:fixed;
  right:27vw;
  top:37vh;
}

#sky #rect-01{
  position:fixed;
  left:30%;
  top:10vh;
  opacity:0.35;
  filter: blur(1px);
}

#sky #rect-02{
  position:fixed;
  right:41%;
  top:10vh;
  filter: blur(2px);
  opacity:0.35;
}


#ground{
  z-index:6;
}
.ground-fade{
  position:fixed;
  bottom:0;
  left:0;
  width:100vw;
  background: linear-gradient(0deg, #FFF 0%, rgba(255, 255, 255, 0.55) 45%, rgba(255, 255, 255, 0.00) 100%);
  z-index: 1;
  height:35vh;
}
#ground .circle-01{
  position:fixed;
  bottom:0;
  left:0;
}

#ground .circle-02{
  position:fixed;
  bottom:0;
  right:0;
  opacity:0.6;
}
#ground .circle-03{
  position:fixed;
  bottom: -2510px;
  right: -110%;
}
#ground .circle-04{
  position:fixed;
  bottom: -440px;
  left: -18%;
}

#ground .circle-01,
#ground .circle-02,
#ground .circle-03,
#ground .circle-04{
  transition: all 0.5s ease-in-out;
}

@media screen and (max-width:1280px){
  #ground .circle-01{
    bottom: -150px;
    left: -130px;
  }

  #ground .circle-02{
    bottom:-300px;
  }

  #ground .circle-03{
    bottom: -2510px;
    right: -110%;
  }

  #ground .circle-04{
    bottom: -520px;
    left: -58%;
  }

}

@media screen and (max-height:780px){
  #ground .circle-01{
    bottom: -150px;
    left: -130px;
  }

  #ground .circle-02{
    bottom:-300px;
  }

  #ground .circle-03{
    bottom: -2700px;
    right: -200%;
  }

  #ground .circle-04{
    bottom: -520px;
    left: -58%;
  }

}




/* === SO global theme === */

#silkoak{
  --silkoak-color-01: #fff;
  --silkoak-color-02: #DA80FF;
  --silkoak-color-03: #6209D3;
  --silkoak-bg: radial-gradient(50% 50% at 0% 0%, #B500FF 0%, #80F 200%);
  --silkoak-text-color: var(--silkoak-color-01);
  min-height:100vh;
  background: var(--silkoak-bg);
  --bs-border-radius: 3px;
}

#silkoak .btn.btn-primary {
  --bs-btn-bg: var(--silkoak-color-03);
  --bs-btn-border-color: var(--silkoak-color-03);
  --bs-btn-hover-bg: var(--silkoak-color-02);
  --bs-btn-hover-border-color: var(--silkoak-color-02);
  --bs-btn-active-bg: var(--silkoak-color-03);
  --bs-btn-active-border-color: var(--silkoak-color-03);
}

#silkoak .btn.btn-outline-primary {
  --bs-btn-color: var(--silkoak-color-03);
  --bs-btn-border-color: var(--silkoak-color-02);
  --bs-btn-hover-border-color: var(--silkoak-color-02);
  --bs-btn-hover-bg: var(--silkoak-color-02);
  --bs-btn-active-bg: var(--silkoak-color-03);
  --bs-btn-active-border-color: var(--silkoak--color-03);
  --bs-btn-bg: var(--silkoak-color-02);
  --bs-btn-disabled-bg: var(--silkoak-color-02);
  --bs-btn-disabled-border-color: var(--silkoak-color-02);
  --bs-btn-disabled-opacity:0.5 !important;
  --bs-btn-disabled-color:#fff;
}

.text-link{
  color:var(--silkoak-color-03);
}

.text-link:hover{
  color:var(--silkoak-color-02) !important;
}


@media all and (max-width:768px){

  .lw-logo{
    max-width:50%;
    height:auto;
  }

  .brands-combined{
    width:65%;
    height:auto;
    margin-left:auto;
    margin-right:auto;
  }

}

/* Making sure our body is not hidden behind the fading footer */
.content-body {
  z-index: 2;
  position: relative;
}
